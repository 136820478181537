import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dataBranch: {},
    typeBranch: [],
    isLogin: false
  },
  getters: {
  },
  mutations: {
    setDataBranch(state, data) {
      state.dataBranch = data
    },
    setTypeBranch(state, data) {
      state.typeBranch = data.data
    },
    setIsLogin(state, data) {
      state.isLogin = data
    }
  },
  actions: {
    getOneBranch() {
      return axios.get(`/web/branch/${this.BranchId}`).then(({data}) => {
          console.log(data,"============");
          context.commit('setDataBranch', data)
      }).catch(err => {
          console.log(err);
      })
    },
    getLocalStorage(context) {
      if(localStorage.getItem("access_token")) {
        context.commit('setIsLogin', true)
      }
    }
  },
  modules: {
  }
})
