<template>
  <div class="TypeAbsen">
    <div class="title">
        <p id="titleText">Database Type Absen</p>
    </div>
    <!-- {{this.typeabsens}} -->
    <div class="headMain">
        <button id="addButton"
            type="button"
            @click.prevent="showAdd()"
            >
        <div class="leftSide">
                <img src="../assets/plus-square.png" id="plusImg" alt="">
                <p id="lefttext"> Tambah Type Absen</p>
                
        </div>
        </button>
            <button>

                <img src="../assets/printer.png" id="printer" alt="" @click.prevent="downloadData()">
            </button>
    </div>
    <div class="loading" v-if="isLoading">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <!-- {{this.typeabsens}} -->
    <table>
        <thead>
            <tr>
                <th class="text-center">No.</th>
                <th>Nama Cabang</th>
                <th class="text-center">Jenis Absen</th>
                <th class="text-center" colspan="2">Biaya</th>
                <th class="text-center">Jam Absen</th>
                <th  class="text-center">Action</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="e in this.typeabsens" :key="e.id">
                <td class="text-center">{{e.id}} </td>
                <td> {{e.Branch.branchName}} </td>
                <td > {{e.typeName}} </td>
                <td>Rp</td>
                <td class="text-right"> {{e.amount}} </td>
                <td class="text-center"> {{e.timeStart}}  - {{e.timeEnd}}</td>

                <td>
                    <div class="actionFill">
                        <a @click.prevent="showEdit(e)" title="edit">
                            <img src="../assets/edit.png" alt="">
                        </a>
                        <a @click.prevent="deleteTypeAbsen(e.id)" title="edit">
                            <img src="../assets/trash.png" alt="">
                        </a>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> - </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
       <p class="mr-1"> {{this.currentPage}} </p>
       <p class="mr-1">/</p>
       <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="branchModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-if="!isEdit" id="exampleModalLabel">Tambah Cabang</h5>
                    <h5 class="modal-title" v-if="isEdit" id="exampleModalLabel">Edit Cabang</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="isEdit ? editTypeAbsen() : addTypeAbsen()">      
                        <p id="titleInput"> Lokasi </p>
                         <select v-model="BranchId" @change="this.getTypeAbsen">
                         <option value="0" disabled> Pilih lokasi </option>
                            <option v-for="e in this.branches" :key="e.id" :value="e.id"> {{e.branchName}} </option>
                        </select>
                        <p id="titleInput"> Jenis Absen </p>
                        <input type="text" placeholder="Makan Siang" v-model="typeName" required>
                        <p id="titleInput"> Mulai </p>
                        <input type="time" v-model="timeStart" required>
                        <p id="titleInput"> Selesai </p>
                        <input type="time"  v-model="timeEnd" required>
                        <p id="titleInput"> Nilai </p>
                        <input type="number"  v-model="amount" required>
                    <button id="submit" type="submit">
                        Tambah
                    </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import axios from "../API/axios"
import Swal from "sweetalert2";
import csvDownload from 'json-to-csv-export'

export default {
    name: "DatabaseCabang",
    data() {
        return {
            isLoading: false,
            currentPage: 1,
			perPage: 20,
            totalPage : 1,
            id: 0,
            branches: [],
            typeabsens:[],
            BranchId: 0,
            amount: 0,
            timeStart: "",
            timeEnd: "",
            amount: 0,
            isEdit: false,
            typeName: "",

        }
    },
    created() {
        this.getBranch()
        this.getTypeAbsen()
    },
    methods: {
        downloadData() {
            alert("belum ada")
            
        },

        showAdd() {
            this.isEdit = false
           $('#branchModal').modal('show')
             this.timeEnd = ""
               this.timeStart = ""
               this.typeName = ""
               this.amount = ""
               this.BranchId = 0
        },
        showEdit(e) {
            this.id = e.id
            this.timeEnd = e.timeEnd
            this.timeStart = e.timeStart
            this.typeName = e.typeName
            this.BranchId = e.BranchId
            this.amount = e.amount
            this.isEdit = true
           $('#branchModal').modal('show')
        },
        getTypeAbsen() {
             axios({
                method: "GET",
                url: "/web/tabletypeabsen",
                params: {
                    page: this.currentPage,
                    limit: this.perPage,
                }
            }).then(({data}) => {
                console.log(data, "==========");
                this.typeabsens = data.data2.result
                let totalPage = Math.ceil(data.data2.dataLength / this.perPage)
                this.totalPage = totalPage
             }).catch(err => {
                console.log(err);
            })
        },
        addTypeAbsen() {
            let payload = {
                BranchId: this.BranchId,
                timeStart : this.timeStart,
                timeEnd : this.timeEnd,
                typeName : this.typeName,
                amount:  this.amount
            }

            axios({
                url: '/web/typeabsen',
                method: 'post',
                data: payload
            }).then(data => {
                this.getTypeAbsen()
                $('#branchModal').modal('hide')
                Swal.fire(
                     'Berhasil',
                    'Data Telah berhasil Dutambahkan',
                    'success'
                )
            }).catch(err => {
                $('#branchModal').modal('hide')
                Swal.fire(
                    'Maaf',
                   'Periksa Kembali Data Anda',
                   'error'
               )
            })
        }, 
        deleteTypeAbsen(id) {
            axios.delete(`/web/typeabsen/${id}`)
            .then(data => {
                this.getTypeAbsen()
                Swal.fire(
                     'Berhasil',
                    'Data Telah berhasil dihapus',
                    'success'
                )
            }).catch(err => {
                this.getTypeAbsen()
                Swal.fire(
                    'Maaf',
                   'Periksa Kembali Data Anda',
                   'error'
               )
            })
        },  
        editTypeAbsen() {
          let payload = {
            timeEnd : this.timeEnd,
            timeStart : this.timeStart,
            typeName : this.typeName,
            BranchId : this.BranchId,
            amount : this.amount,
          }

          axios({
            method: "PUT",
            url: `/web/typeabsen/${this.id}`,
            data: payload
        }).then(data => {
                this.getTypeAbsen()
                $('#branchModal').modal('hide')
                Swal.fire(
                    'Berhasil',
                    'Data Telah berhasil Dutambahkan',
                    'success'
                    )
        }).catch(err => {
            console.log(err);
                this.getTypeAbsen()
                $('#branchModal').modal('hide')
                Swal.fire(
                    'Maaf',
                   'Periksa Kembali Data Anda',
                   'error'
               )
            })
        },
        getBranch() {
            return axios.get("/web/branch").then(({data}) => {
                this.branches = data
            }).catch(err => {
                console.log(err);
            })
        },
        addPage() {
            this.currentPage = this.currentPage + 1
            this.getTypeAbsen()
        },
        minPage() {
            this.currentPage = this.currentPage - 1
            this.getTypeAbsen()
        }


    }
}
</script>

<style scoped>
.TypeAbsen {
    display: flex;
    flex-direction: column;
    background-color: #EDEDED;
    width: 100%;
    min-height: 100vh;
    padding: 30px 40px;
}
input {
    margin-bottom: 24px;
}
.headMain {
    display: flex;
    width: 100%;
    /* flex-direction: row; */
    justify-content: space-between;
}
.leftSide{
    display: flex;
}
table {
    margin-top: 20px;
    border: 1px rgb(232, 228, 228) solid;
    border-radius:20;
}
table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: rgba(229, 242, 235, 1);
    font-size: 16px;
}
table td {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: rgb(255, 255, 255);
    font-size: 16px;
}
tbody tr {
    border-bottom: 0.2px solid rgb(182, 174, 174);
}
#plusImg {
    color: #fff;
    margin-right: 8px;
}
p {
    margin: 0px;
    margin-bottom: 0;
}

button {
    border: none;
    
}
.title {
    margin-bottom: 20px;
}
#titleText{

    font-size: 20px;
    font-weight: 800;
}
.modal-content {
    border-radius: 20px;
    padding: 24px;
}
.modal-title{
    font-size: 18px;
    font-weight: bolder;
}
.modal-content p{
   color: rgba(158, 158, 158, 1);
   font-size: 12px;
   margin-bottom: 4px;
   padding-left: 5px;
}
select{
    font-size: 16px;
    padding: 10px 20px;
    border: 1px rgb(232, 228, 228) solid;
    border-radius: 20px;
    padding: 10px 20px;
    font-weight: 400;
    margin-bottom: 16px;
}
img {
 height: 24px;
width: 24px;
}

</style>