import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginPage from "../views/LoginPage.vue"
import DatabaseEmployee from "../views/Database.vue"
import DatabaseCabang from "../views/BranchDatabase.vue"
import AbsenList from "../views/AbsenList.vue"
import Personal from "../views/Personal.vue"
import TypeAbsen from "../views/TypeAbsen.vue"
import Position from "../views/Position.vue"


import Swal from "sweetalert2";
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: LoginPage,
    meta: { requiresAuth2: true }

  },
  {
    path: '/databaseEmployee',
    name: 'databaseEmployee',
    component: DatabaseEmployee,
    meta: { requiresAuth: true }
  },
  {
    path: '/brancdatabase',
    name: 'brancdatabase',
    component: DatabaseCabang,
    meta: { requiresAuth: true }
  },
  {
    path: '/absenlist',
    name: 'AbsenList',
    component: AbsenList,
    meta: { requiresAuth: true }
  },
  {
    path: '/personal',
    name: 'Personal',
    component: Personal,
    meta: { requiresAuth: true }
  },
  {
    path: '/typeabsen',
    name: 'TypeAbsen',
    component: TypeAbsen,
    meta: { requiresAuth: true }
  },
  {
    path: '/position',
    name: 'Position',
    component: Position,
    meta: { requiresAuth: true }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => { // pengecekan meta required
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!localStorage.getItem('access_token')) {
      Swal.fire("Maaf", "Anda Harus Login Terlebih dahulu", "error");
      next({
        path: '/',
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

router.beforeEach((to, from, next) => { // pengecekan meta required
  if (to.matched.some(record => record.meta.requiresAuth2)) {
    if (localStorage.getItem('access_token')) {
      Swal.fire("Assalamualaikum", "Kalla Beton Pasti Bisa", "success");
      next({
        path: '/typeabsen',
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})
export default router
