<template>
  <div class="TypeAbsen">
    <div class="title">
        <p id="titleText">Jabatan</p>
    </div>
    <!-- {{this.typeabsens}} -->
    <div class="headMain">
        <button id="addButton" type="button" @click.prevent="showAdd()">
            <div class="leftSide">
                <img src="../assets/plus-square.png" id="plusImg" alt="">
                <p id="lefttext"> Tambah Jabatan</p>
            </div>
        </button>
            <!-- <button>
                <img src="../assets/printer.png" id="printer" alt="" @click.prevent="downloadData()">
            </button> -->
    </div>
    <div class="loading" v-if="isLoading">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <!-- {{this.typeabsens}} -->
    <table v-if="!isLoading">
        <thead>
            <tr>
                <th>Posisi</th>
                <th >Jumlah Pegawai</th>
                <th  class="text-center">Action</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="e in this.dataTable" :key="e.id">
                <!-- <td class="text-center">{{e.id}} </td> -->
                <td>{{ e.positionName }}</td>
                <td>{{ e.totalEmployees }}</td>

                <td>
                    <div class="actionFill">
                        <a @click.prevent="showEdit(e)" title="edit">
                            <img src="../assets/edit.png" alt="">
                        </a>
                        <a @click.prevent="deleteData(e.id)" title="edit">
                            <img src="../assets/trash.png" alt="">
                        </a>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> - </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
       <p class="mr-1"> {{this.currentPage}} </p>
       <p class="mr-1">/</p>
       <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="branchModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-if="!isEdit" id="exampleModalLabel">Tambah Jabatan</h5>
                    <h5 class="modal-title" v-if="isEdit" id="exampleModalLabel">Edit Jabatan</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="isEdit ? editData() : addData()">      
                        <p id="titleInput"> Nama Posisi :  </p>
                        <input type="text" placeholder="Boss / Teknis / Driver / Staff / dll" v-model="positionName" required>
                        <button id="submit" type="submit" > Ok</button>
                       
                    </form>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import axios from "../API/axios"
import Swal from "sweetalert2";
import csvDownload from 'json-to-csv-export'

export default {
    name: "DatabaseCabang",
    data() {
        return {
            id: 0,
            isEdit: false,
            isLoading: false,
            currentPage: 1,
			perPage: 20,
            totalPage : 1,
            dataTable:[],
            positionName: "",

        }
    },
    created() {
        this.getDataTable()
    },
    methods: {

        showAdd() {
            $('#branchModal').modal('show')
        },
        showEdit(e) {
            this.isEdit = true
            this.id = e.id
            this.positionName = e.positionName
            $('#branchModal').modal('show')
        },

        async getDataTable() {
            this.isLoading = true
            try {
                let {data} = await axios({
                    method: "GET",
                    url: "/web/position",

                })
                console.log(data);
                this.dataTable = data
                this.isLoading = false
            } catch(err) {
                this.isLoading = false
                console.log(err);
            }
        },

        async addData() {
            try {
                alert("yesss")
                this.isLoading = true
                let data = await axios({
                    method: "POST",
                    url: "/web/position",
                    data: {
                        positionName: this.positionName,
                    }
                })
                $('#branchModal').modal('hide')
                this.isLoading = false
                this.getDataTable()
                Swal.fire(
                     'Berhasil',
                    'Data Telah berhasil Dutambahkan',
                    'success'
                )

                console.log(data);
            } catch(err) {
                $('#branchModal').modal('hide')
                this.isLoading = false
                Swal.fire(
                     'Maaf',
                    'Periksa Kembali Data Anda',
                    'error'
                )
                console.log(err);
            }
        },
        async editData() {
            try {
                alert("yesss")
                this.isLoading = true
                let data = await axios({
                    method: "put",
                    url: `/web/position/${this.id}`,
                    data: {
                        positionName: this.positionName,
                    }
                })
                $('#branchModal').modal('hide')
                this.isLoading = false
                this.getDataTable()
                Swal.fire(
                     'Berhasil',
                    'Data Telah berhasil Dutambahkan',
                    'success'
                )

                console.log(data);
            } catch(err) {
                $('#branchModal').modal('hide')
                this.isLoading = false
                Swal.fire(
                     'Maaf',
                    'Periksa Kembali Data Anda',
                    'error'
                )
                console.log(err);
            }
        },

        addPage() {
            this.currentPage = this.currentPage + 1
            this.getTypeAbsen()
        },
        minPage() {
            this.currentPage = this.currentPage - 1
            this.getTypeAbsen()
        }


    }
}
</script>

<style scoped>
.TypeAbsen {
    display: flex;
    flex-direction: column;
    background-color: #EDEDED;
    width: 100%;
    min-height: 100vh;
    padding: 30px 40px;
}
input {
    margin-bottom: 24px;
}
.headMain {
    display: flex;
    width: 100%;
    /* flex-direction: row; */
    justify-content: space-between;
}
.leftSide{
    display: flex;
}
table {
    margin-top: 20px;
    border: 1px rgb(232, 228, 228) solid;
    border-radius:20;
}
table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: rgba(229, 242, 235, 1);
    font-size: 16px;
}
table td {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: rgb(255, 255, 255);
    font-size: 16px;
}
tbody tr {
    border-bottom: 0.2px solid rgb(182, 174, 174);
}
#plusImg {
    color: #fff;
    margin-right: 8px;
}
p {
    margin: 0px;
    margin-bottom: 0;
}

button {
    border: none;
    
}
.title {
    margin-bottom: 20px;
}
#titleText{

    font-size: 20px;
    font-weight: 800;
}
.modal-content {
    border-radius: 20px;
    padding: 24px;
}
.modal-title{
    font-size: 18px;
    font-weight: bolder;
}
.modal-content p{
   color: rgba(158, 158, 158, 1);
   font-size: 12px;
   margin-bottom: 4px;
   padding-left: 5px;
}
select{
    font-size: 16px;
    padding: 10px 20px;
    border: 1px rgb(232, 228, 228) solid;
    border-radius: 20px;
    padding: 10px 20px;
    font-weight: 400;
    margin-bottom: 16px;
}
img {
 height: 24px;
width: 24px;
}

</style>