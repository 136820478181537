<template>
  <div class="databaseEmployee">
    <div class="title">
        <p id="titleText">Database Karyawan</p>
    </div>
    <!-- <input type="number" v-model="currentPage" >
    {{this.employees}}
    {{this.totalPage}} -->
    <div class="headMain">
        <button id="addButton"
            type="button"
            @click.prevent="showAdd()"
            >
        <div class="leftSide">
                <img src="../assets/plus-square.png" id="plusImg" alt="">
                <p id="lefttext"> Tambah Pegawai</p>
        </div>
        </button>
            <button>

                <img src="../assets/printer.png" id="printer" alt="">
            </button>
    </div>
    <table>
        <thead>
            <tr>
                <th class="text-center">No.</th>
                <th>Nama</th>
                <th>Jabatan</th>
                <th>Username</th>
                <th>No. Rekening</th>
                <th class="text-center">Action</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="e in employees" :key="e">
                <td th class="text-center"> {{e.index}} </td>
                <td> {{e.employeeName}} </td>
                <td> {{e.position}} </td>
                <td> {{e.username}} </td>
                <td> {{e.rekNo || '-'}} </td>
                <td class="text-center">
                    <div class="actionFill">
                        <img src="../assets/edit.png" @click.prevent="showEdit(e)" alt="">
                        <img src="../assets/reset.png" @click.prevent="resetPass(e.id)" alt="" title="reset password">
                        <img src="../assets/trash.png" @click.prevent="confirmDelete(e.id)" alt="" title="hapus password">
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> - </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
       <p class="mr-1"> {{this.currentPage}} </p>
       <p class="mr-1">/</p>
       <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
    </div>
    <!-- Modal -->
    <div class="modal fade" id="userModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-if="!isEdit" id="exampleModalLabel">Tambah Karyawan</h5>
                    <h5 class="modal-title" v-if="isEdit" id="exampleModalLabel">Edit Karyawan</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="isEdit ? editEmployee() : addEmployee()">      
                        <p id="titleInput"> Nama Lengkap </p>
                        <input type="text" placeholder="nama lengkap" v-model="employeeName">
                        <p id="titleInput"> Jabatan </p>
                        <select v-model="PositionId" required>
                            <option v-for="e in this.positions" :key="e" :value="e.id"
                            >{{e.positionName}}
                        </option>
                        </select>
                        <p id="titleInput" > Rek No. </p>
                        <input type="text" placeholder="Nomor Rekening"  v-model="rekNo" required >
                        <p id="titleInput"> Username </p>
                        <input type="text" v-if="!this.isEdit" placeholder="username"  v-model="username">
                        <input  v-if="this.isEdit" type="text" placeholder="username"  v-model="username" disabled>
                        <p id="titleInput" v-if="this.isEdit == false"> Password </p>
                        <input type="password" placeholder="Password"  v-model="password" v-if="this.isEdit == false" required >
                    <button id="submit" type="submit">
                        Submit
                    </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import axios from "../API/axios"
import Swal from "sweetalert2";
export default {
    name: "Database",
    data() {
        return {
            id: 0,
            currentPage: 1,
			perPage: 20,
            totalPage : 1,
            username: "",
            position: "",
            employeeName: "",
            rekNo: '',
            password: "",
            PositionId: 0,
            jabatans: ['Boss', 'Manager','Karyawan','Cheker'],
            isEdit: false,
            employees: '',
            dataPrint: [],
            positions : []
        }
    },
    created() {
        this.getEmployee()
        this.getPosisi()
    },
    methods: {
        showAdd() {
            $('#userModal').modal('show')
            this.isEdit = false
            this.username= "",
            this.id= 0,
            this.position= "",
            this.employeeName= "",
            this.password= ""
        },
        showEdit(e) {
            $('#userModal').modal('show')
            this.id = e.id
            this.isEdit = true
            this.username = e.username
            this.PositionId = e.PositionId
            this.rekNo = e.rekNo
            this.employeeName = e.employeeName
        },
        printCsv() {
            this.isLoading = true
            axios({
                method: "GET",
                url: '/web/print/branch',
                params: {
                    BranchId: this.BranchId,
                    month : moment(this.selectedMonth).format("MM"),
                    year : moment(this.selectedMonth).format("YYYY")
                },
                headers: {
                    access_token: localStorage.getItem("access_token")
                }
            })
            .then(data => {
                this.dataPrint= data.data
                
                const dataToConvert = {
                data: this.dataPrint,
                filename: 'daftar absen per cabang',
                delimiter: ',',
                }
                csvDownload(dataToConvert)
                // const to = setTimeout(() => {
                //     this.isLoading = false
                //     clearTimeout(to)
                // }, 3000)
                this.isLoading = false

            })
        },
        async getEmployee() {
            try {

                const { data } = await axios(
                {
                    method: 'get',
                    url: "/web/employee",
                    params: {
                        page: this.currentPage,
                        limit: this.perPage,
                    },
                    headers: {
                        access_token: localStorage.getItem("access_token")
                    }
                });
                // console.log(data);
                let totalPage = Math.ceil(data.data2.dataLength / this.perPage);
                this.totalPage = totalPage;
                this.employees = data.data2.result;
            } catch (err) {
                console.log(err);
            }
        },
        async getPosisi() {

            try {
                let {data} = await axios({
                    method: "GET",
                    url: "/web/position",

                })
                console.log(data);
                this.positions = data
            } catch(err) {
                console.log(err);
            }
        },
        addEmployee() {
            let payload = {
                username : this.username,
                employeeName : this.employeeName,
                PositionId : this.PositionId,
                password : this.password,
                rekNo : this.rekNo,
            }
           return axios.post("/web/employee", payload, {
                        headers: {
                        access_token: localStorage.getItem("access_token")
                        }
           })
           .then(data => {
                this.getEmployee()
                this.username= ""
                this.employeeName= "",
                this.position= "",
                this.password= "",
                this.rekNo= "",
               $('#userModal').modal('hide')
                Swal.fire(
                    'Berhasil',
                    'Anda telah menambahkan 1 karyawan',
                    'success'
                )
           })
           .catch(err => {
               Swal.fire(
                   'Maaf',
                   'Permintaan Anda Ditolak',
                   'error'
               )
                $('#userModal').modal('hide')
            console.log(err);
           })
        },
        editEmployee() {
            
            let payload = {
            employeeName: this.employeeName,
            PositionId : this.PositionId,
			rekNo: this.rekNo,
            }
            axios({
                method: 'put',
                url: `/web/employee/${this.id}`,
                data: payload,
                headers: {
                    access_token: localStorage.getItem("access_token")
                }
            })
            .then(data => {
                this.getEmployee()
                this.username= ""
                this.employeeName= "",
                this.position= "",
                this.password= "",
               $('#userModal').modal('hide')
                Swal.fire(
                    'Berhasil',
                    'Anda telah menambahkan 1 karyawan',
                    'success'
                )
           })
           .catch(err => {
               Swal.fire(
                   'Maaf',
                   'Permintaan Anda Ditolak',
                   'error'
               )
                // $('#userModal').modal('hide')
            console.log(err);
           })
        },
        confirmDelete(id) {
            Swal.fire({
                title: 'Yakin?',
                text: 'Apakah Yakin ',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Hapus!!',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteEmployee(id);
                } else {
                        Swal.fire('Ok', 'Proses anda berhasil dibatalkan', 'error');
                }
            });
        },
        deleteEmployee(id) {
            return axios.delete(`/web/employee/${id}`,{
                headers: {
                    access_token: localStorage.getItem("access_token")
                }
            }).then(data => {
                Swal.fire(
                    'Berhasil',
                    'Anda telah menambahkan 1 karyawan',
                    'success'
                )
                this.getEmployee()
            }).catch(err => {
                console.log(err);
            })
        },
        resetPass(id) {
            return axios({
                url: `/web/resetpass/${id}`,
                method: 'patch',
                headers: {
                    access_token: localStorage.getItem("access_token")
                }
            })
            .then(data => {
                this.getEmployee()
                Swal.fire(
                    'Berhasil',
                    'Data Berhasil diubah',
                    'success'
                )
           })
           .catch(err => {
               Swal.fire(
                   'Maaf',
                   'Permintaan Anda Ditolak',
                   'error'
               )
                // $('#userModal').modal('hide')
            console.log(err);
           })
        },  
        addPage() {
            this.currentPage = this.currentPage + 1
            this.getEmployee()
        },
        minPage() {
            this.currentPage = this.currentPage - 1
            this.getEmployee()
        }

    }
}
</script>

<style scoped>
.databaseEmployee {
    display: flex;
    flex-direction: column;
    background-color: #EDEDED;
    width: 100%;
    padding: 30px 40px;
    min-height: 100vh;
}
.headMain {
    display: flex;
    width: 100%;
    /* flex-direction: row; */
    justify-content: space-between;
}
.leftSide{
    display: flex;
}
table {
    margin-top: 20px;
    border: 1px rgb(232, 228, 228) solid;
    border-radius:20;
}
table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: rgba(229, 242, 235, 1);
    font-size: 16px;
}
table td {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: rgb(255, 255, 255);
    font-size: 16px;
}
tbody tr {
    border-bottom: 0.2px solid rgb(182, 174, 174);
}
#plusImg {
    margin-right: 8px;
}
p {
    margin: 0px;
    margin-bottom: 0;
}
img {
 height: 24px;
width: 24px;
}
button {
    border: none;
    background-color: transparent;
}
.title {
    margin-bottom: 20px;
}
#titleText{

    font-size: 20px;
    font-weight: 800;
}
.modal-content {
    border-radius: 20px;
    padding: 24px;
}
.modal-title{
    font-size: 18px;
    font-weight: bolder;
}
.modal-content p{
   color: rgba(158, 158, 158, 1);
   font-size: 12px;
   margin-bottom: 4px;
   padding-left: 5px;
}
input {
    width: 100%;
    padding: 10px 20px;
     border: 1px rgb(232, 228, 228) solid;
     border-radius: 20px;
     font-size: 14px;
    margin-bottom: 24px;

}
select{
    /* background-color: red; */
    font-size: 14px;
    padding: 10px 20px;
    width: 100%;
    border: 1px rgb(232, 228, 228) solid;
    border-radius: 20px;
    padding: 10px 20px;
    margin-bottom: 24px;
}


img {
 height: 24px;
width: 24px;
}

</style>