<template>
  <div class="Personal">
    <div class="title">
        <p id="titleText">Absen Karyawan</p>
    </div>
    <!-- {{this.employees}} -->
    <div class="headMain mb-3">
        <select v-model="EmployeeId" @change="this.getUserAbsens" id="branchSelect">
            <option> <input type="text"/> </option>
            <option  :value="0" @change="this.getUserAbsens()">Seluruh Karyawan</option>
            <option  v-for="e in this.employees" :key="e.id" :value="e.id" @change="this.getUserAbsens()"> {{e.employeeName}} </option>
        </select>
            <button @click.prevent="printCsv()">
                <img src="../assets/printer.png" id="printer" alt="">
            </button>
    </div>
    <table id="HeadTitle" style="border-collapse:none !important;">
        <tbody>

            <tr>
                <td id="titleTable">Kehadiran</td>
                <td>:</td>
                <td>{{this.absenUser.dataLength || 0}}</td>
            </tr>
            <tr>
                <td id="titleTable">Total Bulanan</td>
                <td>:</td>
                <td>Rp. {{moneyFormat(this.absenUser.total) || 0}}</td>
            </tr>
            <tr>
                <td id="titleTable">Nomor Rekening</td>
                <td>:</td>
                <td>{{ rekNo }}</td>
            </tr>
        </tbody>
    </table>
    <div class="inRow mt-3">
        <div class="inCol mr-2">
            <p>Dari Tanggal</p>
            <b-input type="date" v-model="startDate" @change="this.getUserAbsens"></b-input>
        </div>
        <div class="inCol">
            <p>Sampai Tanggal</p>
            <b-input type="date" v-model="endDate" @change="this.getUserAbsens"></b-input>
        </div>
    </div>

    
    <div class="loading" v-if="isLoading">
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <table id="tableData" v-if="!isLoading">
        <thead>
            <tr>
                <th>Tanggal</th>
                <th>Jam</th>
                <th>Jenis</th>
                <th>Nama</th>
                <th>Lokasi</th>
                <th>Status</th>
                <th colspan="2" class="text-center">Total</th>
                <th class="text-center">Foto</th>
                <th class="text-center" colspan="2">Action</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="e in this.absenUser" :key="e.id">
                <td >{{ formatDate(e.date)}} </td>
                <td> {{e.time}} </td>
                <td> {{e.TypeAbsen.typeName}} </td>
                <td>{{ e.Employee.employeeName }}</td>
                <td> {{e.Branch.branchName}}  </td>
                <td> {{e.statusAbsen}}  </td>
                <td class="text-left"> Rp. </td>
                <td class="text-right"> {{moneyFormat(e.amount)}} </td>
                <td class="text-center"> <button @click.prevent="openImage(e.absenPic)">  {{e.absenPic}}</button> </td>
                <td class="text-center">
                    <button @click.prevent="confirmChangeStatus(e.id)" title="Ubah Status">
                        <lord-icon
                            src="https://cdn.lordicon.com/nxaaasqe.json"
                            trigger="hover"
                            style="width:32px;height:32px">
                        </lord-icon>
                    </button>
                </td>
                <td class="text-center">
                    <button @click.prevent="confirmDelete(e.id)" title="Hapus Absen">
                        <lord-icon
                            src="https://cdn.lordicon.com/gsqxdxog.json"
                            trigger="hover"
                            style="width:32px;height:32px">
                        </lord-icon>
                    </button>
                </td>
            </tr>
        </tbody>
    </table>
    <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> - </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p>
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
    </div>

    <!-- Modal -->
   
  </div>
</template>

<script>
import axios from "../API/axios"
import VueMonthlyPicker from 'vue-monthly-picker'
import Swal from "sweetalert2";
import moment from "moment";
import { MonthPickerInput } from 'vue-month-picker'
import csvDownload from 'json-to-csv-export'

export default {
    name: "Personal",
    components: {
        VueMonthlyPicker,
        MonthPickerInput
    },
    data() {
        return {
            id: 0,
            isLoading: false,
            startDate: '',
            endDate: '',
            currentPage: 1,
			perPage: 20,
            totalPage : 1,
            EmployeeId: 0,
            branches: [],
            absenUser: "",
            employees: "",
            absens:"",
            branch: '',
            selectedMonth: "",
            month: 0,
            year: 0,
            dataPrint: [],
            rekNo: "",
        }
    },
    created() {
        this.getEmployees()
        this.getUserAbsens()
    },
    computed: {
        dataBranch() {
            return this.$store.state.dataBranch
        }
    },
    watch: {
        // currentPage() {
        //     this.getAbsenRecap()
        // }
    },
    methods: {
       
        showAdd() {
            $('#absenModal').modal('show')
        },
        openImage(text) {
            window.open(`https://readyfooddb.otnielpangkung.com/${text}`)
            // window.open(`http://localhost:3001/${text}`)
        },
        printCsv() {
            this.isLoading = true
            axios({
                method: "GET",
                url: '/web/print/personal',
                params: {
                    EmployeeId: this.EmployeeId,
                    startDate: this.startDate,
                    endDate: this.endDate,
                },
                headers: {
                    access_token: localStorage.getItem("access_token")
                }
            })
            .then(data => {
                let employeeName = 'Seluruh Karyawan'
                if(this.EmployeeId !== 0 ) employeeName = this.employees.find(e => e.id == this.EmployeeId).employeeName
                let tanggal = ''

                if(this.startDate !== '') tanggal += `dari ${this.formatDate(this.startDate)}`
                if(this.endDate !== '') tanggal += `Sampai ${this.formatDate(this.endDate)}`


                this.dataPrint= data.data
                
                const dataToConvert = {
                data: this.dataPrint,
                filename: `Absen ${employeeName} ${tanggal} `,
                delimiter: ',',
                }
                csvDownload(dataToConvert)
                const to = setTimeout(() => {
                    this.isLoading = false
                    clearTimeout(to)
                }, 3000)
                this.isLoading = false
                
            })
            .catch(err => {
                this.isLoading = true
                console.log(err);
            })
        },
        moneyFormat(data) {
            if(!data) return 0
            else {
                let uang = ""
                    data = data.toString();
                    for (let i = 0; i < data.length; i++) {
                        if ((data.length - i) % 3 == 0 && i !== 0) {
                            uang += `.${data[i]}`;
                        } else {
                            uang += data[i];
                        }
                    }
                    return uang;
            }
        },
        async getEmployees() {
            
            try {
                this.isLoading = true
                let {data} = await axios.get("/web/allemployee", {
                    headers: {
                    access_token: localStorage.getItem("access_token")
                }}
                )
                this.isLoading = false
                this.employees = data
            } catch(err) {
                this.isLoading = false
                console.log(err);
            }
            
        },
        async getUserAbsens() {

            try {
                let {data} = await axios.get("/web/absenuser", {
                params: {
                    page: this.currentPage,
                    limit: this.perPage,
                    EmployeeId: this.EmployeeId,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    
                },
                headers: {
                    access_token: localStorage.getItem("access_token")
                }
            })
            console.log(data);
                this.rekNo = this.employees.find(e => e.id == this.EmployeeId)?.rekNo || '-'
                this.absenUser = data.data2.result
                let totalPage = Math.ceil(data.data2.dataLength / this.perPage)
                this.totalPage = totalPage

            } catch(err) {
                console.log(err )

            }
        },
        formatDate(data) {
            let result = ''
            if(data) {
                result = moment(data).format("DD-MM-YYYY")
            }
            return result
        },
         addPage() {
             this.currentPage = this.currentPage + 1
            this.getUserAbsens()
        },
        minPage() {
            this.currentPage = this.currentPage - 1
            this.getUserAbsens()
        },
        confirmChangeStatus(id) {
            Swal.fire({
                title: 'Yakin?',
                text: 'Apakah Yakin ',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ubah',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.changeStatus(id);
                } else {
                        Swal.fire('Ok', 'Proses anda berhasil dibatalkan', 'error');
                }
            });
        },
        changeStatus(id) {
            // return alert(id)
            axios({
                url: `/web/status/${id}`,
                method: 'PATCH',
                headers: {
                    access_token: localStorage.getItem("access_token")
                }
            })
            .then(data => {
                Swal.fire('Berhasil!', 'Data Berhasil Diubah', 'success');
                this.getUserAbsens()
            }).catch(err => {
                console.log(err )
            })
        },
        confirmDelete(id) {
            Swal.fire({
                title: 'Yakin?',
                text: 'Apakah Yakin ',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Hapus',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteAbsen(id);
                } else {
                    Swal.fire('Ok', 'Proses anda berhasil dibatalkan', 'error');
                }
            });
        },
        
        async deleteAbsen(id) {
            
            try {
                
                let data = await axios({
                    url: `/web/absen/${id}`,
                    method: 'delete',
                    headers: {
                        access_token: localStorage.getItem("access_token")
                    }
                })
                Swal.fire('Berhasil!', 'Data Berhasil Diubah', 'success');
                this.getUserAbsens()
                
            } catch(err) {
                console.log(err);
            }   
        },

    }
}
</script>

<style scoped>
.Personal {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    background-color: #EDEDED;
    width: 100%;
    padding: 30px 40px;
}
.headMain {
    display: flex;
    width: 100%;
    /* flex-direction: row; */
    justify-content: space-between;
}
.leftSide{
    display: flex;
}
#HeadTitle td {
    font-size: 20px;
    /* border-collapse: separate !important;
    border: none; */

}
#HeadTitle  {

    border-collapse: separate !important;
    /* border: none; */
}
#titleTable {
    width: 200px;
}
#tableData {
    margin-top: 20px;
    border: 1px rgb(232, 228, 228) solid;
    border-radius:20;
}
#tableData th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: rgba(229, 242, 235, 1);
    font-size: 16px;
}
#tableData td {
    padding-top: 0px;
    padding-bottom: 0px;
    text-align: left;
    background-color: rgb(255, 255, 255);
    font-size: 14px;
}
tbody tr {
    border-bottom: 0.2px solid rgb(182, 174, 174);
}
#plusImg {
    margin-right: 8px;
}
p {
    margin: 0px;
    margin-bottom: 0;
}
.secondHead {
    display: flex;
    padding: 0px;
    margin-top: 8px;
}
img {
 height: 24px;
width: 24px;
}
button {
    border: none;
    background-color: transparent;
}
.title {
    margin-bottom: 20px;
}
#titleText{

    font-size: 20px;
    font-weight: 800;
}
.modal-content {
    border-radius: 20px;
    padding: 24px;
}
.modal-title{
    font-size: 18px;
    font-weight: bolder;
}
.modal-content p{
   color: rgba(158, 158, 158, 1);
   font-size: 12px;
   margin-bottom: 4px;
   padding-left: 5px;
}
input {
    width: 100%;
    padding: 10px 20px;
     border: 1px rgb(232, 228, 228) solid;
     border-radius: 20px;
     font-size: 14px;
    margin-bottom: 24px;

}
select{
    font-size: 16px;
    padding: 10px 20px;
    border: 1px rgb(232, 228, 228) solid;
    border-radius: 20px;
    padding: 10px 20px;
    font-weight: bolder;
    margin-bottom: 16px;
}
#branchSelect {
    width: 30%;
}
#submit{
    padding: 10px 20px;
    background-color:  #00803C ;
    color: #fff;
    width: 100%;
    border-radius: 20px;
}
#monthPicker{
    width: 30% ;
    margin-top: 20px;
    font-size: 14px;
    font-weight: bolder;
    border: #EDEDED 1px solid;
    border-radius: 60px;
}



</style>