import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueQRCodeComponent from 'vue-qrcode-component'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
// import VueMonthlyPicker from 'vue-monthly-picker'
import { MonthPicker } from 'vue-month-picker'
import { MonthPickerInput } from 'vue-month-picker'


// Register the Vue component
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'


Vue.component('qr-code', VueQRCodeComponent)
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(MonthPicker)
Vue.use(MonthPickerInput)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
