<template>
  <div class="app">
      <Navbar v-if="this.userLogin"/>
    <div class="main">
      <div class="sideBar" v-if="this.userLogin">
        <Sidebar />
      </div>
      <div class="mainPage">
        <router-view />
      </div>
    </div>
  </div>
</template>
<script>
import Sidebar from "./components/Sidebar"
import Navbar from "./components/Navbar"
import axios from "./API/axios"

export default {
  name: "App",
  components: {
      Navbar,
      Sidebar
  }, 
  data() { 
    return {
    
    }
  },
  computed: {
    userLogin() {
      return this.$store.state.isLogin;
    }
  },
  methods: {
    deleteFoto() {
      return axios.get("/web/photo")
    }
  },
  created() {
        if (localStorage.getItem("access_token")) {
        this.$store.commit("setIsLogin", true);
        this.deleteFoto()
      }
  }


}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 0;
  margin: 0;
}
*{
  font-family: 'Inter', sans-serif;
}

.app {
  /* width: 100vw; */
  height: 100%;
  width: 100%;
}
.spinner-border{ 
  min-width: 300px;
  min-height: 300px;
  text-align: center;
}
.loading {
  justify-content: center;
  display: flex;
  /* width: 100%; */
}
.sideBar{
  width: 235px;
  /* background-color: #2c3e50; */
}

.mainPage {
  width: 100%;
  display: flex;
  padding: 0;
}
.main {
  /* background-color: aqua; */
  width: 100%;
  height: 100%;
  display: flex;
}
#addButton {
    display: flex;
    background-color:  rgba(0, 128, 60, 1);
    padding: 10px 31px;
    color: #fff;
    border-radius: 40px;
}

.actionFill {
    display: flex;
    justify-content: space-evenly;
}
#submit{
    padding: 10px 20px;
    margin-top: 8px;
    background-color:  #00803C ;
    color: #fff;
    width: 100%;
    border-radius: 20px;
}
input {
    width: 100%;
    padding: 10px 20px;
     border: 1px rgb(232, 228, 228) solid;
     border-radius: 20px;
     font-size: 14px;
    margin-bottom: 40px;
}
select{
    /* background-color: red; */
    font-size: 14px;
    padding: 10px 20px;
    width: 100%;
    border: 1px rgb(232, 228, 228) solid;
    border-radius: 20px;
    padding: 10px 20px;
    margin-bottom: 24px;
}
.pagination {
    display: flex;
    justify-content: center;
}
.inRow {
  display: flex;
}
.inCol {
  width: 100%;
  display: flex;
  flex-direction: column;
}
/* img {
 height: 24px;
width: 24px;
} */
</style>
