<template>
    <div class="Sidebar">
        <router-link to="/absenlist">
            <div class="listMenu">
                <img src="../assets/Daftar Absen.png" alt="">
                <p class="sidebarMenu">Absen Lokasi</p>
            </div>
        </router-link>
        <router-link to="/personal">
            <div class="listMenu">
                <img src="../assets/Absen Karyawan.png" alt="">
                <p class="sidebarMenu">Absen Karyawan</p>
            </div>
        </router-link>
        <router-link to="/databaseEmployee">
            <div class="listMenu">
                <img src="../assets/Database Karyawan.png" alt="">
                <p class="sidebarMenu">Database Karyawan</p>
            </div>
        </router-link>
        <router-link to="/position">
            <div class="listMenu">
                <img src="../assets/position.png" alt="">
                <p class="sidebarMenu">Posisi</p>
            </div>
        </router-link>
        <router-link to="/brancdatabase">
            <div class="listMenu">
                <img src="../assets/Database Cabang.png" alt="">
                <p class="sidebarMenu">Database Cabang</p>
            </div>
        </router-link>
        <router-link to="/typeabsen">
            <div class="listMenu">
                <img src="../assets/u_list-ul.png" alt="">
                <p class="sidebarMenu">Type Absen</p>
            </div>
        </router-link>


    </div>
</template>

<script>
export default {
name: "Sidebar"
}
</script>

<style scoped>
.Sidebar {
    padding-left: 20px;
    padding-top: 16px;
    display: flex;
    height: 100%;
    flex-direction: column;
    /* background-color: rgb(120, 39, 39); */
}

.sidebarMenu{
    color: black;
    font-size: 14px;
    font-weight: bolder;
    text-decoration: none;
    margin-left: 8px;
}
router-link a:hover{
    text-decoration: none;
}
p {
    margin: 0px;
}
.Sidebar a:hover{
    text-decoration: none;
}

.Sidebar a:active{
    color: red;
}
.listMenu {
    display: flex;
    margin-top: 25px;

}
img {
    width: 24px;
    height: 24px;
}



</style>