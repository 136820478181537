<template>
  <div class="databaseEmployee">
    <div class="title">
        <p id="titleText">Database Cabang</p>
    </div>

    <div class="headMain">
        <button id="addButton"
            type="button"
            @click.prevent="showAdd()"
            >
        <div class="leftSide">
                <img src="../assets/plus-square.png" id="plusImg" alt="">
                <p id="lefttext"> Tambah Cabang</p>
                
        </div>
        </button>
            <button>

                <img src="../assets/printer.png" id="printer" alt="">
            </button>
    </div>
    <table>
        <thead>
            <tr>
                <th class="text-center">No.</th>
                <th>Nama Cabang</th>
                <th>No. Rekening</th>
                <th>Latitude</th>
                <th>longitude</th>
                <th  class="text-center">Action</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="e in this.branches" :key="e">
                <td class="text-center">{{e.id}} </td>
                <td> {{e.branchName}} </td>
                <td> {{e.rekNumber}} </td>
                <td> {{e.latitude}} </td>
                <td> {{e.longitude}} </td>
                <td>
                    <div class="actionFill">
                        <button  title="Set Barcode">
                            <img src="../assets/barcode.png" alt="" @click.prevent="seBarcode(e)">
                        </button>
                        <button  title="edit">
                            <img src="../assets/edit.png" alt="" @click.prevent="showEdit(e)">
                        </button>
                        <button @click.prevent="confirmDelete(e.id)" title="edit">
                            <img src="../assets/trash.png" alt="">
                        </button>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <div id="printArea">
        <div class="qr">
            <qr-code :text="qr" :size="500"></qr-code>
        </div>
            <p id="namaCabang">{{ namaCabang }}</p>
     </div>
    <!-- Modal -->
    <div class="modal fade" id="branchModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-if="!isEdit" id="exampleModalLabel">Tambah Cabang</h5>
                    <h5 class="modal-title" v-if="isEdit" id="exampleModalLabel">Edit Cabang</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="isEdit ? editBranch() : addBranch()">      
                        <p id="titleInput"> Nama Cabang </p>
                        <input type="text" placeholder="Cabang" v-model="branchName" required>
                        <p id="titleInput"> Rek Number </p>
                        <input type="text" placeholder="ex: BCA - 9092121" v-model="rekNumber" required>
                        <p id="titleInput"> Latitude </p>
                        <input type="text" placeholder="ex: 7.4929432" v-model="latitude" required>
                        <p id="titleInput"> longitude </p>
                        <input type="text" placeholder="ex: 7.4929432"  v-model="longitude" required>
                    <button id="submit" type="submit">
                        Tambah
                    </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import axios from "../API/axios"
import Swal from "sweetalert2";
import html2pdf from "html2pdf.js";

export default {
    name: "DatabaseCabang",
    data() {
        return {
            id: 0,
            branches: [],
            detail: '',
            rekNumber: '',
            longitude: '',
            latitude: '',
            branchName: "",
            password: "",
            isEdit: false,
            qr: '',
            namaCabang: ''
        }
    },
    created() {
        this.getBranch()
    },
    methods: {
        exportToPDF() {
            document.getElementById('printArea')
            html2pdf(document.getElementById('printArea'),  {
              margin: 1,
              filename: "Barcode.pdf",
            }).then(e => {
              document.getElementById('printArea').style.display='none'
            })
          },
        showAdd() {
            $('#branchModal').modal('show')
            this.branchName = ""
            this.rekNumber = ""
            this.latitude = ""
            this.longitude = ""
            this.isEdit = false
        },
        seBarcode(e) {
            let data = {
                id: e.id,
                Lokasi : e.branchName
            }
            this.namaCabang = e.branchName
            this.qr = JSON.stringify(data)
            this.exportToPDF()
        },
        showEdit(e) {
            this.id = e.id
            this.rekNumber = e.rekNumber
            this.longitude = e.longitude
            this.latitude = e.latitude
            this.branchName = e.branchName
           $('#branchModal').modal('show')
           this.isEdit = true
        },
        async addBranch() {
            let payload = {
                branchName : this.branchName,
                rekNumber : this.rekNumber,
                latitude : this.latitude,
                longitude : this.longitude,
            }
           try {
                const data = await axios.post("/web/branch", payload);
                this.getBranch();
                $('#branchModal').modal('hide');
                Swal.fire(
                'Berhasil',
                'Anda telah menambahkan 1 karyawan',
                'success'
                );
                this.branchName = "";
                this.rekNumber = "";
                this.latitude = "";
                this.longitude = "";
            } catch (err) {
                $('#branchModal').modal('hide');
                Swal.fire(
                'Maaf',
                'Permintaan Anda Ditolak',
                'error'
                );

                console.log(err);
            }
        },
        async getBranch() {
            try {
                const { data } = await axios.get("/web/branch");
                this.branches = data;
            } catch (err) {
                console.log(err);
            }
        },
        async editBranch() {
            // alert("tes")
            try {
            const data = await axios({
                url: `/web/branch/${this.id}`,
                method: "put",
                data: {
                rekNumber: this.rekNumber,
                longitude: this.longitude,
                latitude: this.latitude,
                branchName: this.branchName
                }
            });
                $('#branchModal').modal('hide');
                Swal.fire(
                'Berhasil',
                'Branch Telah berhasil diubah',
                'success'
                );
                this.branchName = "";
                this.rekNumber = "";
                this.latitude = "";
                this.longitude = "";
                this.getBranch();
                this.isEdit = false;
            } catch (err) {
                $('#branchModal').modal('hide');
                Swal.fire(
                'Maaf',
                'Permintaan Anda Ditolak',
                'error'
                );
            }
        },
        confirmDelete(id) {
            Swal.fire({
                title: 'Yakin?',
                text: 'Apakah Yakin ',
                icon: 'question',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Hapus!!',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.hapusCabang(id);
                } else {
                        Swal.fire('Ok', 'Proses anda berhasil dibatalkan', 'error');
                }
            });
        },
        hapusCabang(id) {
            axios.delete(`/web/branch/${id}`, {
                headers: {
                    access_token: localStorage.getItem("access_token")
                }
            }).then(data => {
                Swal.fire('Deleted!', 'Data Berhasil Dihapus', 'success');
                this.getBranch()
            }).catch(err => {
                Swal.fire('Maaf', 'Periksa Kembali data anda', 'error');
            })
        }

    }
}
</script>

<style scoped>
.databaseEmployee {
    display: flex;
    flex-direction: column;
    background-color: #EDEDED;
    width: 100%;
    min-height: 100vh;
    padding: 30px 40px;
}
input {
    margin-bottom: 24px;
}
.headMain {
    display: flex;
    width: 100%;
    /* flex-direction: row; */
    justify-content: space-between;
}
.leftSide{
    display: flex;
}
table {
    margin-top: 20px;
    border: 1px rgb(232, 228, 228) solid;
    border-radius:20;
}
table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: rgba(229, 242, 235, 1);
    font-size: 16px;
}
table td {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: rgb(255, 255, 255);
    font-size: 16px;
}
tbody tr {
    border-bottom: 0.2px solid rgb(182, 174, 174);
}
#plusImg {
    color: #fff;
    margin-right: 8px;
}
p {
    margin: 0px;
    margin-bottom: 0;
}

button {
    border: none;
    
}
.title {
    margin-bottom: 20px;
}
#titleText{

    font-size: 20px;
    font-weight: 800;
}
.modal-content {
    border-radius: 20px;
    padding: 24px;
}
.modal-title{
    font-size: 18px;
    font-weight: bolder;
}
.modal-content p{
   color: rgba(158, 158, 158, 1);
   font-size: 12px;
   margin-bottom: 4px;
   padding-left: 5px;
}

img {
 height: 24px;
width: 24px;
}

table button {
    background-color: transparent;
}

.printArea {

    justify-content: center;
    width: 100%;
    display: none;
}
.qr {
    margin-top: 50px;
    display: flex;
    justify-content: center;
}
#namaCabang {
    margin-top: 12px;
    text-align: center;
    font-size: 42px;
    font-weight: 800;
}

</style>