<template>
<div class="LoginPage row m-0 p-0 justify-content-center align-items-center">
  <div class="LoginBox ">
      <div class="col">
        <div class="row">
          <img src="../assets/logo2.png" alt="" id="logo">
        </div>
        <div class="row">
          <p id="title">Ready Boss</p>
        </div>
        <div class="row">
          <p id="subTitle">Masukkan username dan password.</p>
        </div>
        <form @submit.prevent="login()">
          <div>
            <p id="inputTitle">Username</p>
          </div>
          <div >
            <input type="text" v-model="username">
          </div>
          <div >
            <p id="inputTitle">Password</p>
          </div>
          <div >
            <input type="password" v-model="password">
          </div>
          <div >
            <b-button type="submit"> Login</b-button>
          </div>
        </form>
      </div>
    </div>
</div>
</template>

<script>
import axios from "../API/axios"
import Swal from "sweetalert2";
export default {
name: "LoginPage",
data() {
  return {
    username: "",
    password: ""
  }
},
computed: {
  isLogin() {
    return this.$store.state.isLogin
  }
},
methods: {
  login() {
    let payload= {
      username: this.username,
      password :this.password
    }
    axios({
      method: "POST",
      url: "/web/login",
      data: payload
    }).then(({data}) => {
      localStorage.setItem('access_token', data.access_token)
      Swal.fire("Assalamualaikum", "Kalla Beton Pasti Bisa", "success");
      this.$store.commit("setIsLogin", true);
      this.$store.dispatch('getLocalStorage')
      this.$router.push("/absenlist")
    }).catch(err => {
      Swal.fire("Maaf", "Password/username anda salah", "error");
    })
  }
}
}
</script>

<style scoped>
* {
  margin: 0;
}
.LoginPage {
  display: flex;

  width: 100%;
  padding: 0;
  height: 100vh;
  background-color: #EDEDED;
}
.LoginBox{
  background-color: #FFFFFF;
  border-radius:20px;
  padding-right: 40px;
  padding-left: 40px;
  padding-top: 32px;
  padding-bottom: 32px;
  border: 1px solid rgb(163, 162, 162);
  width: 400px;

}
#logo{
  max-width: 59px;
  max-height: 19px;
  margin-bottom: 32px;
}

#title{
  font-weight: bold;
  font-size: 28px;
  margin-bottom: 4px;
}


#subTitle{
  font-size: 16px;
  font-weight: normal;
  color: #757575;
  margin-bottom: 60px;
}

#inputTitle{
  color: #9E9E9E;
  font-size: 12px;
}
input{
width: 100%;
border-radius: 20px;
border: 1px #E0E0E0 solid;
padding: 10px;
margin-bottom: 25px;
}

button{
 width: 100%;
 border-radius: 20px;
  background-color: #00803C;
}

button:hover{
  color: #00803C;
  background-color: #FFFFFF;
}

input:active input:hover{
  border-color: #00803C;
}

</style>