<template>
  <div class="databaseEmployee">
    <div class="title">
        <p id="titleText">Rekap Absen</p>
        <!-- {{this.dataPrint}} -->
        <div class="loading" v-if="isLoading">
            <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
    </div>
    <div class="headMain mb-3">
        
        <select v-model="BranchId" @change="this.getOneBranch" id="branchSelect">
            <option :value="0"> Seluruh Cabang</option>
            <option  v-for="e in this.branches" :key="e.id" :value="e.id" > {{e.branchName}} </option>
        </select>
            <button @click.prevent="printCsv()">
                <img src="../assets/printer.png" id="printer" alt="">
            </button>
    </div>
    <table id="HeadTitle" style="border-collapse:none;" v-if="!isLoading" >
        <tbody>

            <tr>
                <td id="titleTable">Nomor Rekening</td>
                <td>:</td>
                <td v-if="this.BranchId > 0">{{this.branch.rekNumber}}</td>
            </tr>
            <tr>
                <td id="titleTable">Total</td>
                <td>:</td>
                <td v-if="this.BranchId > 0"> Rp. {{moneyFormat(this.absens.total)}}</td>
            </tr>
        </tbody>
    </table>
    <div class="inRow mt-3">
        <div class="inCol mr-2">
            <p>Dari Tanggal</p>
            <b-input type="date" v-model="startDate" @change="this.getAbsenRecap"></b-input>
        </div>
        
        <div class="inCol">
            <p>Sampai Tanggal</p>
            <b-input type="date" v-model="endDate" @change="this.getAbsenRecap"></b-input>
        </div>
    </div>
    
    <div class="">

        <button id="addButton" class="mt-3"
            type="button"
            @click.prevent="showAdd()"
            >
                <img src="../assets/plus-square.png" id="plusImg" alt="">
                <p id="lefttext"> Tambah Absen Manual</p>
        </button>
    </div>
    

    <table id="tableData"  v-if="!isLoading">
        <thead>
            <tr>
                <th class="text-center">Id.</th>
                <th>Nama Pegawai</th>
                <th>Jabatan</th>
                <th>Kehadiran</th>
                <th colspan="2" class="text-center">Total</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="e in this.absens.result" :key="e.id">
                <td th class="text-center">{{e.id}} </td>
                <td> {{e.Employee.employeeName}} </td>
                <td> {{e.Employee.Position ? e.Employee.Position.positionName : '-'}} </td>
                <td> {{e.kehadiran}}  </td>
                <td class="text-left"> Rp. </td>
                <td class="text-right"> {{moneyFormat(e.amount)}} </td>
            </tr>
        </tbody>
    </table>
    <div class="pagination mt-2">
        <button type="button" class="mr-1" id="pageButton" v-if="currentPage > 1"  @click.prevent="minPage()"> - </button>
        <button class="mr-1" id="pageButton" v-if="currentPage <= 1"  > &lt; </button>
        <p class="mr-1"> {{this.currentPage}} </p>
        <p class="mr-1">/</p>
        <p class="mr-1">{{this.totalPage}} </p> 
        <button type="button" id="pageButton" v-if="this.currentPage < this.totalPage" @click.prevent="addPage()"> > </button>
        <button id="pageButton" v-if="this.currentPage >= this.totalPage" > > </button>
    </div>

    <!-- Modal -->
    <div class="modal fade" id="absenModal" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Tambah Absen Manual</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form @submit.prevent="addAbsenManual()">      
                        <p id="titleInput"> Lokasi </p>
                         <select v-model="addBranchId" @change="this.getTypeAbsen" required>
                            <option v-for="e in this.branches" :key="e.id" :value="e.id"> {{e.branchName}} </option>
                        </select>
                      
                        <p id="titleInput"> nama Karyawan </p>
                         <select v-model="addEmployeeId" required>
                            <option v-for="e in this.employees" :key="e.id" :value="e.id"> {{e.employeeName}} </option>
                        </select>
                        <p id="titleInput"> Type Absen </p>
                         <select v-model="addTypeAbsenId" required>
                            <option v-for="e in this.typeabsens" :key="e.id" :value="e.id"> {{e.typeName}} </option>
                        </select>
                        <p id="titleInput"> Tanggal </p>
                        <input type="date" v-model="date" required>
                        <p id="titleInput"> Jam </p>
                        <input type="time" v-model="time" required>
                        <p id="titleInput"> Keterangan </p>
                        <input type="text" v-model="detail" required>

                    <button id="submit" type="submit">
                        Tambah
                    </button>
                    </form>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import axios from "../API/axios"
import VueMonthlyPicker from 'vue-monthly-picker'
import Swal from "sweetalert2";
import moment from "moment";
import { MonthPickerInput } from 'vue-month-picker'
import csvDownload from 'json-to-csv-export'

export default {
    name: "AbsenList",
    components: {
        VueMonthlyPicker,
        MonthPickerInput
    },
    data() {
        return {
            currentPage: 1,
            isLoading: false,
			perPage: 20,
            totalPage : 1,
            branches: [],
            startDate: '',
            endDate: '',
            typeabsens:[],
            employees: "",
            absens:"",
            BranchId: 0,
            addBranchId: 0,
            addEmployeeId: 0,
            addTypeAbsenId: 0,
            time: "",
            date: "",
            detail: "",
            isEdit: false,
            branch: '',
            selectedMonth: "",
            month: 0,
            year: 0,
            dataPrint: []
        }
    },
    created() {
        
        this.getBranch()
        this.getOneBranch()
        this.getAbsenRecap()
        this.getTypeAbsen()
        this.getEmployees()
    },
    computed: {
        dataBranch() {
            return this.$store.state.dataBranch
        }
    },

    methods: {
        opneImage(text) {
            // console.log(e, "=============");
            window.open(`http://localhost:3001/${text}`)
            // return axios({
            //     method : "GET",
            //     url: 
            // })
       },
        showAdd() {
            $('#absenModal').modal('show')
        },
        printCsv() {
            this.isLoading = true
            axios({
                method: "GET",
                url: '/web/print/branch',
                params: {
                    BranchId: this.BranchId,
                    startDate: this.startDate,
                    endDate: this.endDate,
                    
                },
                headers: {
                    access_token: localStorage.getItem("access_token")
                }
            })
            .then(data => {
                let branchName = 'Seluruh Lokasi'
                if(this.BranchId !== 0 ) branchName = this.branches.find(e => e.id == this.BranchId).branchName
                let tanggal = ''

                if(this.startDate !== '') tanggal += `dari ${this.formatDate(this.startDate)}`
                if(this.endDate !== '') tanggal += `Sampai ${this.formatDate(this.endDate)}`


                this.dataPrint= data.data
                
                const dataToConvert = {
                data: this.dataPrint,
                filename: `Absen ${branchName} ${tanggal} `,
                delimiter: ',',
                }
                csvDownload(dataToConvert)
                // const to = setTimeout(() => {
                //     this.isLoading = false
                //     clearTimeout(to)
                // }, 3000)
                this.isLoading = false

            })
            .catch(err => {
                this.isLoading = false
                console.log(err);
            })
        },
        addAbsenManual() {
            let payload = {
                addEmployeeId: this.addEmployeeId,
                addBranchId : this.addBranchId,
                addTypeAbsenId: this.addTypeAbsenId,
                time: this.time,
                date: this.date,
                detail: this.detail,
            }
             return axios({
                method: "POST",
                url: "/web/absenmanual",
                data: payload,
                headers: {
                    access_token: localStorage.getItem("access_token")
                }

            }).then(data => {
                $('#absenModal').modal('hide')
                Swal.fire(
                    'Berhasil',
                    'Anda telah menambahkan 1 karyawan',
                    'success'
                )
                // this.addEmployeeId = 0
                // this.addBranchId = 0
                // this.addTypeAbsenId = 0
                // this.time = ""
                // this.date = ""
                // this.detail = ""
                this.getAbsenRecap()

            }).catch(err => {
                console.log(err);
            })
        },
        getBranch() {
            return axios.get("/web/branch", {
                headers: {
                    access_token: localStorage.getItem("access_token")
                }
            }).then(({data}) => {
                this.branches = data
            }).catch(err => {
                console.log(err);
            })
        },
        moneyFormat(data) {
            if(!data) return 0
            else {
                let uang = ""
                    data = data.toString();
                    for (let i = 0; i < data.length; i++) {
                        if ((data.length - i) % 3 == 0 && i !== 0) {
                            uang += `.${data[i]}`;
                        } else {
                            uang += data[i];
                        }
                    }
                    return uang;
            }
        },
        getOneBranch() {
            axios.get(`/web/branch/${this.BranchId}`).then(({data}) => {
                this.getTypeAbsen()
                this.getAbsenRecap()
                this.branch = data
            }).catch(err => {
                console.log(err);
            })
        },
        getAbsenRecap() {
            // console.log("sasa");
            return axios.get("/web/absenrecap", {
                params: {
                    page: this.currentPage,
                    limit: this.perPage,
                    BranchId: this.BranchId,
                    startDate: this.startDate,
                    endDate: this.endDate,

                },
                headers: {
                    access_token: localStorage.getItem("access_token")
                }
            })
            .then(data => {
                let totalPage = Math.ceil(data.data.dataLength / this.perPage)
                this.totalPage = totalPage
                this.absens = data.data
            }).catch(err => {
                console.log(err);
            })
        },
        getTypeAbsen() {
            let hasil = []
             axios({
                method: "GET",
                url: "/web/typeabsen",
                headers: {
                    access_token: localStorage.getItem("access_token")
                }
            }).then(data => {
                data.data.forEach(e => {
                    if(e.BranchId == this.addBranchId) {
                        hasil.push(e)
                    }
                })
                this.typeabsens = hasil
             }).catch(err => {
                console.log(err);
            })
            return hasil
        },
        getEmployees() {
            return axios.get("/web/allemployee", 
                {
                    headers: {
                        access_token: localStorage.getItem("access_token")
                    }
                }
            ).then(data => {
                this.employees = data.data
            }).catch(err => {
                console.log(err )
            }) 
        },
         addPage() {
            alert("yess")
            this.currentPage = this.currentPage + 1
            this.getAbsenRecap()
        },
        minPage() {
            this.currentPage = this.currentPage - 1
            this.getAbsenRecap()
        },
    }
}
</script>

<style scoped>
.databaseEmployee {
    display: flex;
    flex-direction: column;
    background-color: #F5F5F5;
    width: 100%;
    min-height: 100vh;
    padding: 30px 40px;
}
.headMain {
    display: flex;
    width: 100%;
    /* flex-direction: row; */
    justify-content: space-between;
}
.leftSide{
    display: flex;
}
#HeadTitle td {
    font-size: 20px;
    /* border-collapse: separate !important;
    border: none; */

}
#HeadTitle  {

    border-collapse: separate !important;
    /* border: none; */
}
#titleTable {
    width: 200px;
}
#tableData {
    margin-top: 20px;
    border: 1px rgb(232, 228, 228) solid;
    border-radius:20;
}
#tableData th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: rgba(229, 242, 235, 1);
    font-size: 16px;
}
#tableData td {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: rgb(255, 255, 255);
    font-size: 16px;
}
tbody tr {
    border-bottom: 0.2px solid rgb(182, 174, 174);
}
#plusImg {
    margin-right: 8px;
}
p {
    margin: 0px;
    margin-bottom: 0;
}
.secondHead {
    display: flex;
    padding: 0px;
    margin-top: 8px;
}
img {
 height: 24px;
width: 24px;
}
button {
    border: none;
    
}
.title {
    margin-bottom: 20px;
}
#titleText{

    font-size: 20px;
    font-weight: 800;
}
.modal-content {
    border-radius: 20px;
    padding: 24px;
}
.modal-title{
    font-size: 18px;
    font-weight: bolder;
}
.modal-content p{
   color: rgba(158, 158, 158, 1);
   font-size: 12px;
   margin-bottom: 4px;
   padding-left: 5px;
}
input {
    width: 100%;
    padding: 10px 20px;
     border: 1px rgb(232, 228, 228) solid;
     border-radius: 20px;
     font-size: 14px;
    margin-bottom: 24px;

}
select{
    font-size: 16px;
    padding: 10px 20px;
    border: 1px rgb(232, 228, 228) solid;
    border-radius: 20px;
    padding: 10px 20px;
    font-weight: bolder;
    margin-bottom: 16px;
}
#branchSelect {
    width: 30%;
}
#submit{
    padding: 10px 20px;
    background-color:  #00803C ;
    color: #fff;
    width: 100%;
    border-radius: 20px;
}
#monthPicker{
    width: 30% ;
    margin-top: 20px;
    font-size: 14px;
    font-weight: bolder;
    border: #EDEDED 1px solid;
    border-radius: 60px;
}

.vue-monthly-picker {
    /* border-color: #00803C !important;  */
    /* border: 3px solid red !important; */
} 

</style>